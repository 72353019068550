export const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const alpha: string = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
const numeric: string = "1234567890";
const isAlpha = (value: string) => value.split("").filter((f) => alpha.indexOf(f) + 1);
const isNumeric = (value: string) => value.split("").filter((f) => numeric.indexOf(f) + 1);

export const validateRef = (ref: string) => {
  if (isAlpha(ref).length > 0 && isNumeric(ref).length > 0) {
    return true;
  } else {
    return false;
  }
};
